<template>

  <div class="main">
    <img src="../../assets/wx-share-1.jpg" style="width: 100%;display: block" alt="">
    <img src="../../assets/wx-share-2.jpg" style="width: 100%;display: block" alt="">
    <img src="../../assets/wx-share-3.jpg" style="width: 100%;display: block" alt="">
<!--    <img src="../../assets/wx-share-4.jpg" style="width: 100%;display: block" alt="">-->

    <div class="see-more" @click="toMyCs">
      打卡领福利
    </div>
  </div>

</template>

<script>
import {showFailToast, userAgentUa} from "../../utils/util";

export default {
  name: "activity",

  setup(){


    const toMyCs = () => {
      window.location.href = window.location.origin;
    }

    return {
      toMyCs,

    }
  }
}
</script>

<style scoped lang="scss">

.main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.see-more {
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  width: calc(100% - 36px);
  background-color: #FB4D09;
  height: 50px;
  border-radius: 16px;
  bottom:calc(env(safe-area-inset-bottom) + 10px);
  text-align: center;
  line-height: 50px;
  font-weight: bold;
  color: #fff;
}

</style>
